

import { useState, useEffect } from "react"
import RecapCom from "../components/Commercial/RecapCom"
import StatisticsVisits from "../components/Commercial/StatisticsVisits"
import { db, auth } from "../firebase.config"
import { onSnapshot, doc } from "firebase/firestore"
import { signOut } from "firebase/auth"
import { useNavigate } from "react-router-dom"


function Preview({ firstname, uid }) {
    const [showDeconnexion, setShowDeconnexion] = useState(false)

    const navigate = useNavigate()

    // Déconnecte le user de la session si indisponible
    useEffect(() => {
        const userRef = doc(db, "users", uid)
    
        const unsubscribe = onSnapshot(userRef, (doc) => {
            if (doc.exists() && doc.data().isUnavailable) {
                setShowDeconnexion(true)
            }
        })
    
        return () => unsubscribe()
    }, [uid])

    const handleLogout = () => {
        signOut(auth).then(() => {
            navigate("/connexion")
        })
    }

    return (     
        <div className="preview-section"> 
            <header>
                <h1>Tableau de bord</h1>
            </header>

            {showDeconnexion && (
                <div className="modal-clotured">
                    <div className="content">
                        <p>Vous avez été déconnecté par un administrateur. <br></br> À bientôt !</p>
                        <button onClick={handleLogout} className="button-colored">Fermer</button>
                    </div>
                </div>
            )}

            <div className="hello">
                <h2>Bonjour, <span className="name">{firstname}</span> !</h2>
                <p>Voici les statistiques de vos visites effectuées.</p>
            </div>
            
            <div className="content-prev">   
                <StatisticsVisits uid={uid} />
                <RecapCom uid={uid} />
            </div>
        </div>
    )
}

export default Preview